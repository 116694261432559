import { Img } from 'openimg/react';
import React, { useState } from 'react'
import { type Selection } from 'react-aria-components';

import { Form, Link, useMatches, type LinkProps, useNavigate, useLocation, useFetcher } from 'react-router';
import logoLine from '#app/assets/logo-line.svg'
import logoImage from '#app/assets/logo.svg'
import { Button } from '#app/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '#app/components/ui/sheet'
import { useLanguage } from '#app/contexts/language-context.tsx'
import { ThemeSwitch } from '#app/routes/resources+/theme-switch.tsx';
import { cn, getUserImgSrc } from '#app/utils/misc.js'
import { FOR_SALE_NAVIGATION, TO_RENT_NAVIGATION } from '#app/utils/providers/navbar.ts';
import { type Theme } from '#app/utils/theme.server.ts';
import { useOptionalUser, useUser } from '#app/utils/user.ts'
import { Icon, type IconName } from './ui/icon'
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuList,
	NavigationMenuTrigger,
	navigationMenuTriggerStyle,
} from './ui/navigation-menu'
import { Button as ButtonUI } from './ui-new/button';
import { Menu, MenuContent, MenuItem } from './ui-new/menu';
// import { Img } from 'openimg/react';

/*
TODO:
5. Dropdown content add image vector for sale (house vector) and for rent (flat)
*/

export function NavigationMenuRoot({ userPreference, isMobile=false }: { userPreference: Theme | null, isMobile?:boolean }) {
	const user = useOptionalUser()
	let { t, currentLang } = useLanguage();
	const matches = useMatches();
	const [sheetOpen, setSheetOpen] = useState(false)
	// const isHomePage = matches.find((m) => m.id === 'routes/_marketing+/index')
	const isForSalePage = matches.find((m) => m.id === 'routes/($lang)._marketing+/for-sale' || m.pathname.startsWith('/el/for-sale/') || m.pathname.startsWith('/en/for-sale/'))
	const isToRentPage = matches.find((m) => m.id === 'routes/($lang)._marketing+/to-rent' || m.pathname.startsWith('/el/to-rent/') || m.pathname.startsWith('/en/to-rent/'))
	const isAgentsPage = matches.find((m) => m.id.includes('routes/($lang).agents'))
	const isMapPage = matches.find((m) => m.id === 'routes/($lang).map+/$')
	const isAboutPage = matches.find((m) => m.id === 'routes/($lang)._marketing+/about')

	return (
	<header className={`z-50 w-full bg-gray-900 dark:bg-background dark:border-b backdrop-blur touch-none ${isMobile ? '' : 'px-[15px]'}`}>
			<div className="container flex h-16 max-w-screen-2xl items-center justify-start px-4 lg:px-6">
				<Sheet open={sheetOpen} onOpenChange={(open) => setSheetOpen(open)}>
					<SheetTrigger asChild>
						<div className="mr-2 lg:hidden cursor-pointer">
							<Icon
								name="menu"
								size="lg"
								className="text-white transition delay-75 duration-100 ease-in-out hover:scale-110"
							/>
							<span className="sr-only"> Toggle navigation menu </span>
						</div>
					</SheetTrigger>
					<SheetContent side="left">
						<nav className="grid gap-6 text-lg font-medium">
							<Link
								onClick={() => setSheetOpen(false)}
								to={`/${currentLang}/`}
								className="group mr-4 grid leading-snug text-white"
							>
								<img src={logoImage} className='mt-1 h-8'/>
								<img src={logoLine} className='h-3 translate-y-0.5'/>
							</Link>
							<Link
								onClick={() => setSheetOpen(false)}
								to={`/${currentLang}/for-sale`}
								className={`text-muted-foreground hover:text-foreground ${isForSalePage ? 'underline decoration-red-500 decoration-4 underline-offset-4' : ''}`}
							>
								{t("Common", "For sale")}
							</Link>
							<Link
								onClick={() => setSheetOpen(false)}
								to={`/${currentLang}/to-rent`}
								className={`text-muted-foreground hover:text-foreground ${isToRentPage ? 'underline decoration-red-500 decoration-4 underline-offset-4' : ''}`}
							>
								{t("Common", "To rent")}
							</Link>
							<Link
								onClick={() => setSheetOpen(false)}
								to={`/${currentLang}/agents/all`}
								className={`text-muted-foreground hover:text-foreground ${isAgentsPage ? 'underline decoration-red-500 decoration-4 underline-offset-4' : ''}`}
							>
								{t("Common", "Find agents")}
							</Link>
							<Link
								onClick={() => setSheetOpen(false)}
								to={`/${currentLang}/map`}
								className={`text-muted-foreground hover:text-foreground ${isMapPage ? 'underline decoration-red-500 decoration-4 underline-offset-4' : ''}`}
							>
								{t("Common", "Map")}
							</Link>
							<Link to={`/${currentLang}/about`} className={`text-muted-foreground hover:text-foreground ${isAboutPage ? 'underline decoration-red-500 decoration-4 underline-offset-4' : ''}`} onClick={() => setSheetOpen(false)}>
							{t("Common", "About us")}
							</Link>
						</nav>
					</SheetContent>
				</Sheet>
				
				<Link
					onClick={() => setSheetOpen(false)}
					to={`/${currentLang}/`}
					className="group lg:mr-8 grid place-items-center leading-snug text-white"
				>
					<img src={logoImage} className='mt-1 h-8 transition lg:group-hover:-translate-x-1'/>
					<img src={logoLine} className='hidden lg:block h-3 transition -translate-y-1 lg:group-hover:translate-x-3 ease-in-out'/>
				</Link>

				<NavigationMenu>
					<NavigationMenuList className="hidden lg:flex">
						<NavigationMenuItem>
							<NavigationMenuTrigger data-active={isForSalePage}>
								{t("Common", "For sale")}
							</NavigationMenuTrigger>
							<NavigationMenuContent>
								<ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
									<li className="row-span-3">
										<NavigationMenuLink asChild>
											<Link
												className="flex h-full w-full select-none flex-col justify-end rounded-md transition duration-200 ease-in-out hover:-translate-y-1 hover:scale-105 bg-gradient-to-t from-muted/50 to-muted hover:to-red-100/80 dark:hover:to-neutral-700 p-6 no-underline outline-none focus:shadow-md"
												to={`/${currentLang}/for-sale`}
												data-testid='for-sale-link'
											>
												<div className="mb-2 mt-4 text-lg font-medium">
													{t("Common", "For sale")}
												</div>
												<p className="text-sm leading-tight text-muted-foreground">
													{t("Common", "Find properties for sale in Cyprus")}
												</p>
											</Link>
										</NavigationMenuLink>
									</li>
									{FOR_SALE_NAVIGATION.map((item) => (
										<ListItem key={item.id} to={`/${currentLang}${item.href}`} title={item.name} />
									))}
								</ul>
							</NavigationMenuContent>
						</NavigationMenuItem>
						<NavigationMenuItem>
							<NavigationMenuTrigger data-active={isToRentPage}>
								{t("Common", "To rent")}
							</NavigationMenuTrigger>
							<NavigationMenuContent>
								<ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
									<li className="row-span-3">
										<NavigationMenuLink asChild>
											<Link
												className="flex h-full w-full select-none flex-col justify-end rounded-md transition duration-200 ease-in-out hover:-translate-y-1 hover:scale-105 bg-gradient-to-t from-muted/50 to-muted hover:to-red-100/80 dark:hover:to-neutral-700 p-6 no-underline outline-none focus:shadow-md"
												to={`/${currentLang}/to-rent`}
											>
												<div className="mb-2 mt-4 text-lg font-medium">
													{t("Common", "To rent")}
												</div>
												<p className="text-sm leading-tight text-muted-foreground">
													{t("Common", "Find properties to rent in Cyprus")}
												</p>
											</Link>
										</NavigationMenuLink>
									</li>
									{TO_RENT_NAVIGATION.map((item) => (
										<ListItem key={item.id} to={`/${currentLang}${item.href}`} title={item.name} />
									))}
								</ul>
							</NavigationMenuContent>
						</NavigationMenuItem>
						<NavigationMenuItem>
							<NavigationMenuLink
								className={navigationMenuTriggerStyle()}
								data-active={isAgentsPage}
								asChild
							>
								<Link to={`/${currentLang}/agents/all`}>{t("Common", "Find agents")}</Link>
							</NavigationMenuLink>
						</NavigationMenuItem>
						<NavigationMenuItem>
							<NavigationMenuLink
								className={navigationMenuTriggerStyle()}
								data-active={isMapPage}
								asChild
							>
								<Link to={`/${currentLang}/map`}>{t("Common", "Map")}</Link>
							</NavigationMenuLink>
						</NavigationMenuItem>
						<NavigationMenuItem>
							<NavigationMenuLink
								className={navigationMenuTriggerStyle()}
								data-active={isAboutPage}
								asChild
							>
								<Link to={`/${currentLang}/about`}>{t("Common", "About us")}</Link>
							</NavigationMenuLink>
						</NavigationMenuItem>
					</NavigationMenuList>
				</NavigationMenu>
				<div className="flex items-center justify-end space-x-2 flex-1">
					{user ? (
						<UserDropdown />
					) : (
						<Button asChild className="h-9 w-9 p-2 sm:px-4 sm:w-auto">
							<Link to={`/${currentLang}/login`}>
								<Icon name="log-in" size="md" className="sm:mr-1" />
								<span className="sr-only sm:not-sr-only">{t("Common", "Log in")}</span>
							</Link>
						</Button>
					)}

					<Button asChild className="hidden sm:block h-9 w-9 p-2 sm:px-4 sm:w-auto">
						<Link to={`/${currentLang}/me/favourites/listings`}>
							<Icon name="heart-solid" size="md" className="2xl:mr-1" />
							<span className="sr-only 2xl:not-sr-only">{t("Common", "Favourites")}</span>
						</Link>
					</Button>

					<LanguageDropDown />
					
					<ThemeSwitch className='h-9 w-9 text-lg bg-neutral-100 text-neutral-900 dark:bg-neutral-700 dark:text-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-600 rounded-md' userPreference={userPreference} formId='theme-switch-navbar' />
				</div>
			</div>
		</header>
	)
}

const ListItem = React.forwardRef<
	React.ElementRef<
		React.ForwardRefExoticComponent<
			LinkProps & React.RefAttributes<HTMLAnchorElement>
		>
	>,
	React.ComponentPropsWithoutRef<
		React.ForwardRefExoticComponent<
			LinkProps & React.RefAttributes<HTMLAnchorElement>
		>
	>
>(({ className, title, to, children, ...props }, ref) => {
	let { t } = useLanguage();

	return (
		<li>
			<NavigationMenuLink asChild>
				<Link
					ref={ref}
					className={cn(
						'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
						className,
					)}
					to={to}
					{...props}
				>
					<div className="text-sm font-medium leading-none">{t('Common', title || '')}</div>
					<p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
						{children}
					</p>
				</Link>
			</NavigationMenuLink>
		</li>
	)
})
ListItem.displayName = 'ListItem'

const UserDropdown = () => {
	const user = useUser()
	let { t, currentLang } = useLanguage();
	const formRef = React.useRef<HTMLFormElement>(null)

	return (
		<Menu>
			<ButtonUI variant="subtle" className="flex items-center gap-2 h-9 w-9 p-2 sm:px-4 sm:w-auto">
				<Link
					to={`/${currentLang}/me`}
					// this is for progressive enhancement
					onClick={(e) => e.preventDefault()}
					className="flex items-center gap-2"
				>
					<Img
						className="h-5 w-5 rounded-full object-cover"
						alt={user.name ?? user.username}
						src={getUserImgSrc(user.image?.objectKey)}
						width={256}
						height={256}
					/>
					<div className="hidden sm:block sm:max-w-16 lg:hidden xl:block 3xl:max-w-32 4xl:max-w-40 text-body-xs truncate">
						{user.name ?? user.username}
					</div>
					<Icon name='chevron-down' className='hidden sm:block lg:hidden xl:block'/>
				</Link>
			</ButtonUI>
			<MenuContent className='text-sm'>
				<MenuItem id="user-profile">
					<Link prefetch="intent" to={`/${currentLang}/me`}>
						<Icon className="text-body-md" name="avatar">
							{t("Common", "Profile")}
						</Icon>
					</Link>
				</MenuItem>
				<MenuItem id="settings">
					<Link prefetch="intent" to={`/${currentLang}/settings/profile`} >
						<Icon className="text-body-md" name="gear-1">
							{t("Common", "Settings")}
						</Icon>
					</Link>
				</MenuItem>
				<MenuItem id="baz1">
					<Form action="/logout" method="POST" ref={formRef}>
						<button type="submit" className="w-full">
							<Icon className="text-body-md" name="exit">
								{t("Common", "Logout")}
							</Icon>
						</button>
					</Form>
				</MenuItem>
			</MenuContent>
		</Menu>
	)
}

const LanguageDropDown = () => {
	const { t, currentLang, switchLang } = useLanguage()
	const navigate = useNavigate()
	const fetcher = useFetcher()
	let location = useLocation();

	const onValueChange = async (keys: Selection) => {
		const selection = Array.from(new Set(keys))
		if (selection.length === 1) {
			switchLang(selection[0] || 'en' as any)
			const loc = location.pathname.replace(/^\/(en|el)(?=\/|$)/, "");
			await fetcher.submit(null, {
				method: 'POST',
				action: `/api/change-language/${selection[0]}`,
			})
			await navigate(`/${selection[0]}${loc}${location.search}`)
		}
	}

	return (
		<Menu>
			<ButtonUI variant='subtle' className="flex items-center gap-2 h-9 px-2 text-sm" aria-label='Change language button'>
				<span>{currentLang === 'en' ? 'EN' : 'ΕΛ'}</span>
				<Icon name='chevron-down' className='hidden sm:block'/>
			</ButtonUI>
			<MenuContent
				selectionMode="single"
				selectedKeys={new Set([currentLang])}
				onSelectionChange={onValueChange}
			>
				<MenuItem id="el" key="el" aria-label='Greek language'>
					<LanguageDropDownItem iconName='gr-flag' mainText={t('Common', 'Greek')} subText={t('Common', 'GR')}/>
				</MenuItem>
				<MenuItem id="en" key="en" aria-label='English language'>
					<LanguageDropDownItem iconName='en-flag' mainText={t('Common', 'English')} subText={t('Common', 'UK')}/>
				</MenuItem>
			</MenuContent>
		</Menu>
	)
}

const LanguageDropDownItem = ({ iconName, mainText, subText }:{ iconName:IconName, mainText:string, subText:string }) => {
	return (
		<>
			<Icon name={iconName} className='mr-1' size='sm'/>
			<span className='font-semibold text-sm'>{mainText}</span>
			<span className='font-light text-xs'>&nbsp;({subText})</span>
		</>
	)
}